import React, {  } from 'react'
import { Pipeline } from "src/assembled"
import { Footer } from 'src/components'
import { SEO } from "src/components/utils"


export default function PipelinePage({ location }) {
    // seo
    const seo = <SEO
    title="Pipeline"
    description="디어젠이 가지고 있는 파이프라인을 소개합니다. 국내 및 해외 유수의 제약사들과 협업중인 파이프라인도 있으며 디어젠이 자체적으로 진행증인 4D 파이프라인(Deargen Driven Drug Development)도 있습니다." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Pipeline />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}